(function($) {
  "use strict";
  // menu fixed js code
  $(window).scroll(function() {
  

    var window_top = $(window).scrollTop() + 1;
    if (window_top > 50) {
      $('.main_menu').addClass('fixed-top animated fadeInDown');
    } else {
      $('.main_menu').removeClass('fixed-top animated fadeInDown');
    }
  });

}(jQuery));


$(document).ready(function() {

  $("#btnForCode").click(function(e) {
    // disable button
    $(this).prop("disabled", true);
    // add spinner to button
    $(this).html(
      `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please Wait...`
    );
    setTimeout(function() {
      /*submit the form after 5 secs*/
      e.preventDefault();
      $('#collapse2FV').removeClass('show');
      $('#verifyCode').show();
    }, 3000)
  });
  $('#sendCode').click(function(e) {
    // disable button
    $(this).prop("disabled", true);
    // add spinner to button
    $(this).html(
      `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please Wait...`
    );
    setTimeout(function() {
      /*submit the form after 5 secs*/
      e.preventDefault();
      $('#verifyCode').hide();
    }, 3000)

  });

  let expanded =  false;
  $(document).on('click', '.expand-all', function(e) {
    if (!expanded) {
      expanded = true;
      $('.show_all').addClass('show');
      $(".expand-all").text("Collapse All");
    } else {
      expanded = false;
      $('.show_all').removeClass('show');
      $(".expand-all").text("Expand All");
    }
  });

  $('#quickReviewAccordion .collapse').on('show.bs.collapse', function() {
    $(this).siblings('.card-header').addClass('active');
  });

  $('#quickReviewAccordion .collapse').on('hide.bs.collapse', function() {
    $(this).siblings('.card-header').removeClass('active')
  });

  // $(".alert").delay(1000).fadeOut(3000); 

  // $("#searchby").change(function() {
  //   $(this).find("option:selected").each(function() {
  //     var optionValue = $(this).attr("value");
  //     if (optionValue) {
  //       $(".box").not("." + optionValue).hide();
  //       $("." + optionValue).show();
  //     } else {
  //       $(".box").hide();
  //     }
  //   });
  // }).change();

  $(document).find('.close').on('click', function(){
    $(this).parent().hide();
  });

  $(".new_data").bind("click", function(event) {
    c = $(".count").length
    if (c == 5) {
      $('#id_link').css("display", "none").removeClass("d-inline-flex");
    }
  });
});