// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import "./jquery.min";
import "./select2";
import "./multiselect_jquery";


import "./custom";
import "./toggle";
import "./error_handling";
//import "./stream";
import "chartkick";
import "bootstrap"
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("chart.js");
require ("jquery-mask-plugin");
//= require_tree
// require("chartkick");
//= require jquery3
//= require popper
//= require jquery_ujs
//= require bootstrap
//= require moment
//= require bootstrap-datetimepicker

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//

const images = require.context("../images", true);
const imagePath = (name) => images(name, true);
