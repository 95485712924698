$(document).on("ready turbolinks:load", function () {

  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })
  
  $.jMaskGlobals.watchDataMask = true;


  $(".error-handle").focus(function () {
    $("#error").hide();
  });

  // delete fields in invites
  var wrapper = $(".inviteSigner"); //Fields wrapper

  var x = 1; //initlal text box count
  var check = $('input[type="checkbox"]');
  check.prop("checked", false);
  $(document).on("click", "#check_all", function (e) {
    check.prop("checked", check.prop("checked"));
  });
  $(document).on("click", "#add_cart_button", function (e) {
    var length = $("#wholesale_patient_id :selected").length;
    if (length > 0) {
      $("#add_cart_form").submit();
    }
  });

  $(document).on("click", "#cart_button", function (e) {
    var len = $("[type='checkbox']:checked").length;
    if (len > 0) {
      $("#cart_form").submit();
    }
  });

  $(wrapper).on("click", ".add_fields", function (e) {
    //user click on add text
    x++;
  });
  $(wrapper).on("click", ".remove", function (e) {
    //user click on remove text

    if (x > 1) {
      e.preventDefault();
      $(this).parent().remove();
      x--;
    }
  });
  
  $("#patient_id_skip").on("click", function () {
    $(".has_insurance").prop("required", false);
    $(".has_insurance").val("");

  });
  $("#check_patient_insurance").on("click", function (e) {
    let status = $('input[name="user_insurance[has_insurance]"]:checked').val();
    if (status == "true") {
      $("#insurance_fields").removeClass("d-none");
      $(".has_insurance").prop("required", true);
      $("#con_step_5").removeClass("col-md-12");
      $("#con_step_5").addClass("col-md-9");
      $("#skip_step_5").removeClass("d-none");
    } else {
      $("#insurance_fields").addClass("d-none");
      $(".has_insurance").prop("required", false);
      $("#con_step_5").removeClass("col-md-9");
      $("#con_step_5").addClass("col-md-12");
      $("#skip_step_5").addClass("d-none");
      $(".has_insurance").val("");
    }
  });

  $("#Upload_image_form").on("submit", function (e) {
    $("#loader_button").removeClass("d-none");
    $("#next_step_button").addClass("d-none");
  });


  $("#patient_1st_form_submit").on("click", function (e) {
    let patient_birthday_year = $("#wholesale_patient_dob_1i").val();
    let patient_birthday_month = $("#wholesale_patient_dob_2i").val();
    let patient_birthday_day = $("#wholesale_patient_dob_3i").val();
    let patient_dob = `${patient_birthday_year}-${patient_birthday_month}-${patient_birthday_day}`;
    let age = calculate_age(patient_dob);
    $("#patient_age").val(age); 
  });

  function calculate_age(dob) {
    let new_dob = new Date(dob);
    var diff_ms = Date.now() - new_dob.getTime();
    var age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }

  function isOver18(dateOfBirth) {
    const date18YrsAgo = new Date();
    date18YrsAgo.setFullYear(date18YrsAgo.getFullYear() - 18);
    return dateOfBirth <= date18YrsAgo;
  }

  $("#wholesale_patient_phone_no").on("keypress", function (e) {
    const pattern = /^[0-9]$/;
    return pattern.test(e.key);
  });

  $("#wholesale_patient_phone_no").on("keyup", function (e) {
    if (
      ($(this).val().length > 0 && $(this).val().substr(0, 2) != "+1") ||
      $(this).val() == ""
    ) {
      $(this).val("+1");
    }
  });
  
  $("#user_insurance_relationship").on("change", function () {
    let selected_relationship = $(this).val();
    if (selected_relationship == "self") {
      $("#user_insurance_subscriber_firstname").val($("#user_insurance_subscriber_firstname").attr("data-firstname"));
      $("#user_insurance_subscriber_lastname").val($("#user_insurance_subscriber_lastname").attr("data-lastname"));
    }
    else{
      $("#user_insurance_subscriber_firstname").val("");
      $("#user_insurance_subscriber_lastname").val("");
    }
  });
  $("#insurance_image_upload_id").on("change", function () {
    $("#insurance_preview_id").attr("src", URL.createObjectURL(this.files[0]));
    $("#insurance_preview_id").removeClass("d-none");
   
  });

  $("#patient_id_upload").on("change", function () {
    $("#patient_id_preview").attr("src", URL.createObjectURL(this.files[0]));
    $("#patient_id_preview").removeClass("d-none");
  });

  
  $('.js-example-basic-multiple').select2();

  // dropdown select for cart_items
  jQuery('#languageSelect').multiselect({
    columns: 1,
    placeholder: 'Select Patients',
    search: true,
    selectAll: true,
});

$('#hiddenselect option').attr('selected','selected');

});
