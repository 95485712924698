$(document).on('turbolinks:load', function(){
  $('.show-more').bind("click", function (event){
    $(".extended-text").toggleClass("show")
    $('.show-more').hide()
  })

  $(".custom-control-input").change( function(){
    val = null
    check_id = null
    if (this.checked){
      val = 'active'
    }
    else {
      val = "out_of_service"
    }
    $.ajax({
      url: '/labissues/update_machine_status/',
      type: 'POST',
      data: {"machine_status": val, "machine_id": this.id.split('-')[1]}
    });
  });

  $( ".add_fields" ).bind( "click", function (event) {
    var regexp, time;
    time = new Date().getTime();
    regexp = new RegExp($(this).data('id'), 'g');
    $(this).before($(this).data('fields').replace(regexp, time));
    return event.preventDefault();
  });
})

$( document ).ready(function() {
  $( "#update_close_btn" ).bind( "click", function() {
    $('.modal').click();
  });
});

$(document).on('turbolinks:load', function(){

  $( ".password_visible" ).bind( "click", function (event) {
    if ($(".password_visible_field")[0].type == 'password') {
      field_text = 'visibility_on'
      field_type = 'text'
    }
    else
    {
      field_type = 'password'
      field_text = 'visibility_off'
    }
    $(".password_visible")[0].innerHTML = field_text
    $(".password_visible_field").removeAttr("type").prop('type', field_type);
    $(".password_visible")[1].innerHTML = field_text
  });

  $( ".delete_check_box" ).change( function(e){
      e.target.closest('.nested_row').setAttribute("style", "display:none !important;");
  });

  $(".import-type").change(function(){
    $(this).find("option:selected").each(function(){
      var optionValue = $(this).attr("value");
      if(optionValue == 'patient_lists' )
      {
        $(".labtest-div").show();
        $(".list-name-div").show()
        $(".labtest-div").show();
      }else if ( optionValue == 'specimen_lists'){
        $(".list-name-div").hide()
        // $(".labtest-div").hide();
      }
       else{
        $(".list-name-div").hide()
        $(".labtest-div").show();
      }
    });
  });
  $(".batch-id").hide()
  $(".labtest-id-select").change(function(){
    $(this).find("option:selected").each(function(){
      var optionValue = $(this).attr("value");
      if(optionValue == '2' )
      {
        $(".batch-id").show();
      }
       else{
        $(".batch-id").hide()
      }
    });
  });

  $(".insurance-id-select").change(function(){
    $(this).find("option:selected").each(function(){
      var optionValue = $(this).attr("value");
      if(optionValue == 'insurance_lists' )
      {
        $(".insurance-div").hide();
      }
       else{
        $(".insurance-div").show()
      }
    });
  });

  $("#show_row_plate").click(function(){
    $('.row-plate').show();
    $('.column-plate').hide();
    updateDirection('row')
  })

  $("#show_column_plate").click(function(){
    $('.row-plate').hide();
    $('.column-plate').show();
    updateDirection('column')
  })

  function updateDirection(direction) {
    $.ajax({
      url: $(".direction-toggle").data('url'),
      type: 'PUT',
      data: { direction: direction }
    });
  }

  $(".form_action.review-form").bind('click', function(e){
    id = this[0].value;
    elem = "#acc_"+id;
    $.ajax({
      url: '/lab_director_dashboard/update_review_status/',
      type: 'POST',
      data: $(this).serialize(),
      success: function() {
        if($(elem).next().hasClass('user-test-card')) {
          $(elem).next().show();
          $(elem).remove();
          $('body').removeClass('modal-open');
          $('#approve-modal-' + id).modal('hide');
          $('.modal-backdrop').remove();
        } else {
          location.reload();
        }
      }
    });
    return false;
  });
})

$(document).on('turbolinks:load', function(){
  var plate_array = ['#working_plate_well_working_plate_id', '#working_plate_plate_id']
  for(var x in plate_array) {
    creatPlate(plate_array[x]);
    $(plate_array[x]).change(function() {
      creatPlate(this);
    });
  }

  function creatPlate(this_el){
    var selected = $(this_el).find(':selected');
    var x = selected.attr('data-dimensions-x');
    var y = selected.attr('data-dimensions-y');

    var plate_name = selected.attr('plate-name');
    var plate_id = selected.attr('value');
    var working_plate_id = selected.attr('working-plate-id');

    var xlength = selected.attr('x-length');
    var ylength = selected.attr('y-length');
    if(selected.val() && working_plate_id)
    {
      get_well_count(x, y, xlength, ylength, plate_name, plate_id, working_plate_id)
    }
  }

  function get_well_count(x, y, xl, yl, plate_name, plate_id, working_plate_id){
    response = []
    $.ajax({
      url: "/working_plate_wells/get_well_count",
      type: "GET",
      data: {x: x, y: y, working_plate_id: working_plate_id},
      success: function(data) {
        console.log(data);
        response = data['remote_ids_arr']
        creatPlateBoard(x, y, xl, yl, plate_name, plate_id, working_plate_id, response)
      }
    })
  }
  function creatPlateBoard(x, y, xl, yl, plate_name, plate_id, working_plate_id, count_array){
    var reserved = ['A1', 'A2', 'A3', 'A4', 'A5']
    $('#create-plate-board').html("");
    $('#create-plate-board').addClass('plate-board row align-items-center').append('<div class="plate-board-inner p-3 mb-3 col"></div>');
    for(var i = 0; i < x; i++)
		{
      var row = $('<div>', { class: 'row m-0 '+i.toString() });
			for(var j = 0; j < y; j++)
			{
        var number = String.fromCharCode(65+i) + (j+1)
        var test_count = count_array[i][j].count
        var remote_ids = count_array[i][j].remote_ids
        if(reserved.includes(number)) {
          test_count = -1;
          remote_ids = [];
        }
        if(!test_count) {
          test_count = 0;
          remote_ids = [];
        }
        var tableHeader = $('<div>', { class: 'parent-lens col p-0 '+j.toString(), html: number + '<a class="d-inline-flex text-decoration-none plate-link" href="#" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#exampleModal"><span class=" fill-' + test_count + ' material-icons lens" data-remote_ids="' + remote_ids + '" data-name="'+ number +'" data-x="'+(i+1)+'" data-y="'+(j+1)+'" plate-name="'+plate_name+'" plate-id="'+plate_id+'" data-working-plate-id="'+working_plate_id+'">lens</span></a>' });
        tableHeader.appendTo(row);
      }
      row.appendTo('.plate-board-inner');
    }
  }

  function get_well_id(x, y, working_plate_id) {
    $.ajax({
      url: "/working_plate_wells/get_well_id",
      type: "GET",
      data: {x: x, y: y, working_plate_id: working_plate_id},
      success: function(data) {
        // if(data.id) {
          console.log('in if ', data)
          // $("#new-form").hide()
          // $("#edit-form").show()
          // $(".simple_form.new_working_plate_well").attr("action", "/working_plate_wells/"+data.id+"")
          // $(".simple_form.edit_working_plate_well").attr("action", "/working_plate_wells/"+data.id+"")
          // $(".simple_form.new_working_plate_well").attr("method","put")

          $('.lens-number').text(data.name);
          // $('#working_plate_well_column').attr("value", y_val);
        // }
        // else {
        //   console.log('in else ', data)
        //   $('#new_working_plate_well #working_plate_well_row').attr("value", x_val);
        //   $('#new_working_plate_well #working_plate_well_column').attr("value", y_val);
        //   $("#edit-form").hide()
        //   $("#new-form").show()
        //   $("#working_plate_well_labtest_id").selectedIndex = 0
        // }
      }
    })
  }

  $(document).on('click', '.mark-complete', function(e){
    $.ajax({type: 'GET', url: '/user_labtests/has_unlinked_specimens',
      dataType: 'json',
      success: function(result) {
        if(result.has_data) {
          $(".alert-js").html('<p class="alert alert-warning">There are specimens that haven\'t been assigned to a well in any run.</p>');
        }
      }
    });
    $('.lens-form').addClass('hidden');
    $('.lens').removeClass('clicked-lens');
    $('.mark-complete').addClass('hidden');
  });

  $(document).on('click', '.parent-lens .clickable', function(e){
    var x_val = $(this).data('x');
    var y_val = $(this).data('y');
    var working_plate_id = $(this).data('working-plate-id');
    var name = $(this).data('name');
    $('.lens-number').text(name);
    $('.lens').removeClass('clicked-lens');
    $(this).find('.lens').addClass('clicked-lens');
    $('.lens-form').removeClass('hidden');
    $('.mark-complete').removeClass('hidden');
    $('#working_plate_well_user_id').focus();

    // get_well_id(x_val, y_val, working_plate_id)

    $('#working_plate_well_row').attr("value", x_val);
    $('#working_plate_well_column').attr("value", y_val);

    $('#working_plate_well_working_plate_id').val(working_plate_id);
    // $('#plateName').text(e.target.getAttribute('plate-name'));
    $('#working_plate_well_user_id').val($(this).data('remote_ids'));

    $('.user-labtest-row').remove()
    if($('.user-labtest-row').length <= 5) {
      $('.add_fields').style = "display:block"
    }
  });
  $('#new_working_plate_well').submit(function(e) {
    var data = $('#new_working_plate_well').serialize();
    e.preventDefault();
    e.returnValue = false;
    $.ajax({type: 'POST', url: '/working_plate_wells',
      dataType: 'json', data: data,
      success: function(result) {
        $('#allowed_duplicate').val(false);
        $('#duplicate-id-modal').modal('hide');
        $('.clicked-lens').css('position', 'inherit');
        $('.clicked-lens').parent().find('.num').remove();
        $(".alert-js").html('');
        var row = "<tr><td>" + result.number + "</td><td>" + result.remote_id + "</td><td>Patient Specimen</td><td>1</td><td>1</td><td>1</td></tr>"
        $('#working-plate-well-info').append(row);
        $('.clicked-lens').removeClass('fill-0');
        $('.clicked-lens').addClass('fill-1');
        var count = $('#working-plate-well-info tr').length;
        $('.extractions').text(count - 1);
        $('.reactions').text(count);
        var prev = $('.clicked-lens').closest('.parent-lens').next();
        if (prev.length == 0) {
          var prev = $('.clicked-lens').closest('.lens-row').next()
        }
        if (prev.length > 0)
          prev.find('.lens')[0].click();
        else {
          $.ajax({type: 'GET', url: '/user_labtests/has_unlinked_specimens',
            dataType: 'json',
            success: function(result) {
              if(result.has_data) {
                $(".alert-js").html('<p class="alert alert-warning">There are specimens that haven\'t been assigned to a well in any run.</p>');
              }
            }
          });
          $('.lens-form').addClass('hidden');
          $('.lens').removeClass('clicked-lens');
          $('.mark-complete').addClass('hidden');
        }
      },
      error: function(result) {
        var msg = result.responseJSON.errors[0];
        if(msg.includes('already been scanned')){
          $('.barcode-id').text($('#working_plate_well_user_id').val());
          $('#duplicate-id-modal').modal('show');
        } else {
          $(".alert-js").html('<p class="alert alert-danger">' + msg + '</p>');
        }
      }
    });
    return false;
  });
  $('.scan-again').click(function(e) {
    $('#allowed_duplicate').val(true);
    $('#new_working_plate_well').submit();
  });
});

function get_data(el, labtest_selector, form){
  console.log("el ", el)
  console.log("labtest_selector ", labtest_selector)
  console.log("form ", form)
  $.ajax({
    url: "/working_plate_wells/populate_userlabtest_list",
    type: "GET",
    data: {labtest_id: $(form+labtest_selector).val()},
    success: function(data) {
      user_labtests_data = ""
      console.log("success", data)
      for(x in data) {
        user_labtests_data += '<option value="'+data[x].id+'">'+data[x].name+'</option>'
      }
      $(el).html(user_labtests_data)
    }
  })
}

$(document).ready(function() {

  var new_form = ".new_working_plate_well "
  var edit_from = ".edit_working_plate_well "
  $(document).on('change',new_form+" #working_plate_well_labtest_id", function(){
    labtest_selector = $(this).find(':selected').text()
    if(labtest_selector != "Please select") {
      get_data('.load_userlabtests', "#working_plate_well_labtest_id", new_form)
    }
  });

  $(document).on('change',edit_from+" #working_plate_well_labtest_id", function(){
    labtest_selector = $(this).find(':selected').text()
    if(labtest_selector != "Please select") {
      get_data('.load_userlabtests', "#working_plate_well_labtest_id", edit_from)
    }
  });

  $(document).on('change', new_form+" .load_userlabtests", function(){
    if($(this).find(':selected').text() == "Click to Load UserLabtests")
    {
      labtest_selector = $(new_form+ ' #working_plate_well_labtest_id').find(':selected').text()
      if(labtest_selector != "Please select") {
        get_data(this, '#working_plate_well_labtest_id', new_form)
      }
    }
  });

  $(document).on('change', edit_from+" .load_userlabtests", function(){
    if($(this).find(':selected').text() == "Click to Load UserLabtests")
    {
      labtest_selector = $('#working_plate_well_labtest_id').find(':selected').text()
      if(labtest_selector != "Please select") {
        get_data(this, '#working_plate_well_labtest_id', edit_from)
      }
    }
  });

    $('.total-patients').map(function(i){
      $(`.showInput`).addClass('hidden')
      $(`#clickees${i} .doclick`).on("click", function(){
        $(`.doclick`).removeClass('blinker2');
        $('#qr_scan').removeAttr('readonly').val('');
        $(`#clickees${i} .doclick`).not('.blinker1').addClass('blinker2');
        $(`#row-count`).text(`${(i+1)}`)

        $(`#row-count-real`).text(`${Math.ceil((i+1)/2)}`)
        $(`.showInput`).removeClass('hidden');
        if ($(`.blinker2`).length == 0){
          $(`.showInput`).addClass('hidden');
          get_row = ($(`#row-count`).text())
          $(`#clickees${get_row-1} .doclick`).removeClass('row_check');
          $(`#clickees${get_row} .doclick`).click()
        }
        if (($(`.blinker1`).length + $(`.blinker2`).length) >= $(`.org_patients`).text()){
          $(`#submit_qr_scan`).text(`Last Scan`);
          console.log("last Scan");
          return "stop";
        }
        else{$(`#submit_qr_scan`).text(`Start Scan`);}
      });
  }).filter(val => val !== "stop");

  $(`#qr_scan`).on('keydown',function(e){
    if (e.code=='Enter'){
      e.preventDefault();
      qrScan=$('#qr_scan').val()
      var wells = [];
      var regex = /Well (\d+)/g;
      var match;
      while ((match = regex.exec(qrScan)) !== null) {
        wells.push(parseInt(match[1]));
      }
      // console.log(wells);
      const uniqueWell = wells.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      var runNum = [];
      regex = /\d{11}\w*/g;
      while ((match = regex.exec(qrScan)) !== null) {
        runNum.push(match[0]);
      }
      // console.log(runNum);
      run = $(`.org_run`).text()
      $.ajax({
        url: '/working_plates/set_userlabtest_well/',
        type: 'POST',
        data: {raw_qr_input : qrScan, qr_runs : runNum, qr_wells : uniqueWell, org_run : run},
        success: function(data) {
          // console.log(data);
          // console.log("data was found");
          $.each(data, function(index, data) {
            var html = "<tr><td>" + data.well_nbr + "</td><td>" + data.remote_identifier + "</td><td> Patient Specimen </td></tr>";
            $('#working-plate-scan-info').append(html)
          })
          $(`.blinker2`).addClass('blinker1');
          get_row = ($(`#row-count`).text())
          $(`#clickees${get_row-1} .doclick`).removeClass('row_check');
          $(`#clickees${get_row} .doclick`).click()
        }
      })
    }
  })

  $('#submit_qr_scan').on('click',function(e){
    e.preventDefault();
    qrScan=$('#qr_scan').val()
    var wells = [];
    var regex = /Well (\d+)/g;
    var match;
    while ((match = regex.exec(qrScan)) !== null) {
      wells.push(parseInt(match[1]));
    }
    // console.log(wells);
    const uniqueWell = wells.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
    var runNum = [];
    regex = /\d{11}\w*/g;
    while ((match = regex.exec(qrScan)) !== null) {
      runNum.push(match[0]);
    }
    // console.log(runNum);
    run = $(`.org_run`).text()
    $.ajax({
      url: '/working_plates/set_userlabtest_well/',
      type: 'POST',
      data: {raw_qr_input : qrScan, qr_runs : runNum, qr_wells : uniqueWell, org_run : run},
      success: function() {
        $(`.blinker2`).addClass('blinker1');
        get_row = ($(`#row-count`).text())
        $(`#clickees${get_row-1} .doclick`).removeClass('row_check');
        $(`#clickees${get_row} .doclick`).click()
      }
    })
  })
});
